window.onload = () => {
	if (window.NodeList && !NodeList.prototype.forEach) {
		NodeList.prototype.forEach = Array.prototype.forEach;
	}
	if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
		HTMLCollection.prototype.forEach = Array.prototype.forEach;
	}

	(function (arr) {
		arr.forEach((item) => {
			if (item.hasOwnProperty('before')) {
				return;
			}
			Object.defineProperty(item, 'before', {
				configurable: true,
				enumerable: true,
				writable: true,
				value: function before() {
					const argArr = Array.prototype.slice.call(arguments),
						docFrag = document.createDocumentFragment();

					argArr.forEach((argItem) => {
						const isNode = argItem instanceof Node;
						docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
					});

					this.parentNode.insertBefore(docFrag, this);
				},
			});
		});
	}([Element.prototype, CharacterData.prototype, DocumentType.prototype]));

	svg4everybody();

	class Slider {
		constructor(rangeElements, valueElements) {
			this.rangeElements = rangeElements;
			this.valueElements = valueElements;

			// Attach a listener to "change" event
			this.rangeElements.forEach((e) => {
				e.addEventListener('input', this.updateSlider.bind(this));
			});
		}

		// Initialize the slider
		init() {
			this.updateSlider()
		}

		generateBackground(value, min, max) {
			const percentage = (value - min) / (max - min) * 100;
			return `linear-gradient(to right, #1C47B7, #1C47B7 ${percentage}%, #fff ${percentage}%, #ffffff 100%)`
		}

		updateSlider(newValue) {
			this.rangeElements.forEach((e) => {
				e.style.background = this.generateBackground(e.value, e.min, e.max);
				e.oninput = function () {
					const slide1 = parseFloat(e.value);
					e.parentElement.querySelector('.range-slider__field').value = slide1;
				}
			})

			this.valueElements.forEach((e) => {
				e.oninput = function () {
					const percentage = (e.value - e.min) / (e.max - e.min) * 100;
					e.parentElement.querySelector('.range-slider__line').value = e.value;
					e.parentElement.querySelector('.range-slider__line').style.background = `linear-gradient(to right, #1C47B7, #1C47B7 ${percentage}%, #fff ${percentage}%, #ffffff 100%)`;
				}
			});
		}
	}

	const rangeElements = document.querySelectorAll('.range-slider__line');
	const valueElements = document.querySelectorAll('.range-slider__field');

	if (rangeElements) {
		const slider = new Slider(rangeElements, valueElements)

		slider.init()
	}
	const sliderBtn = function () {
		const titles = document.querySelectorAll('.slider-btn__title');
		const checkbox = document.querySelectorAll('.slider-btn__field');

		checkbox.forEach((e) => {
			e.addEventListener('change', function () {
				if (this.checked) {
					this.closest('.slider-btn').querySelectorAll('.slider-btn__title')[1].classList.add('slider-btn__title--active');
					this.closest('.slider-btn').querySelectorAll('.slider-btn__title')[0].classList.remove('slider-btn__title--active');
				} else {
					this.closest('.slider-btn').querySelectorAll('.slider-btn__title')[1].classList.remove('slider-btn__title--active');
					this.closest('.slider-btn').querySelectorAll('.slider-btn__title')[0].classList.add('slider-btn__title--active');
				}
			});
		})
	};

	sliderBtn();
	const tab = function () {
		let tabNav = document.querySelectorAll('.tabs__nav-item'), // Выбираем элементы навигации табов
			tabContent = document.querySelectorAll('.tabs__content-item'), // Выбираем самы табы
			tabName; // Переменная для имени таба

		// Проходим циклом по каждому элементу навигации и навешиваем обработчик клика, который вызывает функцию selectTabNav
		tabNav.forEach((item) => {
			item.addEventListener('click', selectTabNav)
		});

		function selectTabNav() {
			tabNav.forEach((item) => {
				// Удаляем активный класс у всех элементов навигации табов
				item.classList.remove('tabs__nav-item--active');
			});
			this.classList.add('tabs__nav-item--active');  // Добавляем активный укласс у элемента по которому кликнули
			tabName = this.getAttribute('data-tab-name'); // Получаем имя таба, который нам нужен
			selectTabContent(tabName); // Запускаем функцию, чтобы показать выбранный таб
		}

		function selectTabContent(tab) {
			// Проходим по всем табам и проверяем есть ли у элемента класс, равный имени таба(переменной tabName). Если есть, то добавляем класс активного таба, если нет, то удаляем этот класс
			tabContent.forEach((item) => {
				const { classList } = item;
				classList.contains(tab) ? classList.add('tabs__content-item--active') : classList.remove('tabs__content-item--active');
			});
		}
	};

	tab();
	const select = () => {
		const selectCurrent = document.querySelectorAll('.select__header'),
			selectItem = document.querySelectorAll('.select__item');
		selectCurrent.forEach((item) => {
			item.addEventListener('click', (e) => {
				e.currentTarget.parentElement.classList.toggle('select--opened');
			})
		});
		selectItem.forEach((item) => {
			item.addEventListener('click', (e) => {
				e.currentTarget.closest('.select').querySelector('.select__current').innerHTML = e.currentTarget.innerHTML;
				e.currentTarget.closest('.select').classList.remove('select--opened');
			})
		});
	};

	select();
	const cardMobile = function () {
		const items = document.querySelectorAll('.card-mobile:nth-child(n+4)');

		if (window.innerWidth <= 575) {
			items.forEach((e) => {
				e.classList.add('card-mobile--hide');
			});
		}
	};

	cardMobile();
	const accordion = function () {
		const accBlock = document.querySelectorAll('.accordion');

		accBlock.forEach((e) => {
			const panel = e.querySelector('.accordion__header').nextElementSibling;

			const checkClass = () => {
				if (e.classList.contains('accordion--active')) {
					panel.style.maxHeight = `${panel.scrollHeight}px`;
				} else {
					panel.style.maxHeight = '0';
				}
			};

			checkClass();

			e.addEventListener('click', () => {
				e.classList.toggle('accordion--active');

				checkClass();
			});
		});
	};

	accordion();
	const scrolltop = function () {
		const item = document.querySelector('.scrolltop');

		window.addEventListener('scroll', () => {
			if (window.pageYOffset > 400) {
				item.classList.add('scrolltop--active');
			} else {
				item.classList.remove('scrolltop--active');
			}
		});
	};

	scrolltop();
	const cloneHeader = function () {
		const header      = document.querySelector('.header'),
			cloneHeader = header.cloneNode(true);

		cloneHeader.classList.add('header--sticky');
		header.before(cloneHeader);

		window.addEventListener('scroll', () => {
			if (window.pageYOffset > 150) {
				cloneHeader.classList.add('header--is-show');
			} else {
				cloneHeader.classList.remove('header--is-show');
			}
		});
	};

	cloneHeader();
	const popupLink = function () {
		const popupLinks = document.querySelectorAll('.js-popup-link');
		const body = document.querySelector('body');
		const lockPadding = document.querySelectorAll('.hidden');

		let unlock = true;

		const timeout = 300;

		if (popupLinks.length > 0) {
			for (let index = 0; index < popupLinks.length; index++) {
				const popupLink = popupLinks[index];
				popupLink.addEventListener('click', (e) => {
					const popupName = popupLink.getAttribute('href').replace('#', '');
					const curentPopup = document.getElementById(popupName);
					popupOpen(curentPopup);
					console.log(popupName);
					e.preventDefault();
				});
			}
		}
		const popupCloseIcon = document.querySelectorAll('.close-popup');
		if (popupCloseIcon.length > 0) {
			for (let index = 0; index < popupCloseIcon.length; index++) {
				const el = popupCloseIcon[index];
				el.addEventListener('click', (e) => {
					popupClose(el.closest('.popup'));
					e.preventDefault();
				});
			}
		}

		function popupOpen(curentPopup) {
			if (curentPopup && unlock) {
				const popupActive = document.querySelector('.popup.popup--opened');
				if (popupActive) {
					popupClose(popupActive, false);
				} else {
					bodyLock();
				}
				curentPopup.classList.add('popup--opened');
				curentPopup.addEventListener('click', (e) => {
					if (!e.target.closest('.popup__content')) {
						popupClose(e.target.closest('.popup'));
					}
				});
			}
		}

		function popupClose(popupActive, doUnlock = true) {
			if (unlock) {
				popupActive.classList.remove('popup--opened');
				if (doUnlock) {
					bodyUnLock();
				}
			}
		}

		function bodyLock() {
			const lockPaddingValue = `${window.innerWidth - document.querySelector('.wrapper').offsetWidth}px`;

			if (lockPadding.length > 0) {
				for (let index = 0; index < lockPadding.length; index++) {
					const el = lockPadding[index];
					el.style.paddingRight = lockPaddingValue;
				}
			}
			body.style.paddingRight = lockPaddingValue;
			body.classList.add('hidden');

			unlock = false;
			setTimeout(() => {
				unlock = true;
			}, timeout);
		}

		function bodyUnLock() {
			setTimeout(() => {
				if (lockPadding.length > 0) {
					for (let index = 0; index < lockPadding.length; index++) {
						const el = lockPadding[index];
						el.style.paddingRight = '0px';
					}
				}
				body.style.paddingRight = '0px';
				body.classList.remove('hidden');
			}, timeout);

			unlock = false;
			setTimeout(() => {
				unlock = true;
			}, timeout);
		}

		document.addEventListener('keydown', (e) => {
			if (e.which === 27) {
				const popupActive = document.querySelector('.popup.popup--opened');
				popupClose(popupActive);
			}
		});

		(function () {
			// проверяем поддержку
			if (!Element.prototype.closest) {
				// реализуем
				Element.prototype.closest = function (css) {
					let node = this;
					while (node) {
						if (node.matches(css)) return node;
						node = node.parentElement;
					}
					return null;
				};
			}
		}());
		(function () {
			// проверяем поддержку
			if (!Element.prototype.matches) {
				// определяем свойство
				Element.prototype.matches = Element.prototype.matchesSelector
                || Element.prototype.webkitMatchesSelector
                || Element.prototype.mozMatchesSelector
                || Element.prototype.msMatchesSelector;
			}
		}());
	};

	popupLink();
	$("input[type='phone']").inputmask({ mask: '+ 7 (999) 999-99-99' });
	const galleryThumbs = new Swiper('.company__thumbs', {
		spaceBetween: 0,
		slidesPerView: 'auto',
		watchOverflow: false,
	});

	const galleryTop = new Swiper('.company__gallery', {
		spaceBetween: 30,
		loop: true,
		speed: 400,
		navigation: {
			nextEl: '.company__gallery-next',
			prevEl: '.company__gallery-prev',
		},
		thumbs: {
			swiper: galleryThumbs,
		},
	});
	const heroMore = function () {
		const moreBtn = document.querySelector('.hero__desc-more');
		const text = document.querySelector('.hero__desc-text');

		if (text) {
			if (window.innerWidth <= 575) {
				const btn = document.createElement('button');
				const innerText = text.innerText.split(' ').slice(0, -1).join(' ');

				btn.classList.add('hero__desc-more');
				btn.innerHTML = 'Подробнее';
				const textSlice = text.innerText.split(' ').slice(0, 13).join(' ');
				text.innerHTML = textSlice;
				text.appendChild(btn);

				btn.addEventListener('click', () => {
					text.removeChild(btn);
					text.innerHTML = innerText;
				});
			}
		}
	};

	heroMore();

	const sandwich = function sandwich() {
		$('.sandwich__open-btn').click(() => {
			$('.sandwich__menu').addClass('sandwich__menu--opened');
			$('.sandwich__content').addClass('sandwich__content--active');
			$('body').addClass('hidden');
		});
		$('.sandwich__close-btn').click(() => {
			$('.sandwich__menu').removeClass('sandwich__menu--opened');
			$('.sandwich__content').removeClass('sandwich__content--active');
			$('.outer-menu').removeClass('outer-menu--opened');
			$('body').removeClass('hidden');
			return false;
		});
		$('.sandwich .sandwich__content').click(() => {
			$('.sandwich__menu').removeClass('sandwich__menu--opened');
			$('.outer-menu').removeClass('outer-menu--opened');
			$('.sandwich .sandwich__content').removeClass('sandwich__content--active');
			$('body').removeClass('hidden');
		});
		$('.main-nav__item').click(function () {
			$(this).children('.outer-menu').addClass('outer-menu--opened');
		});
		$('.outer-menu__back').click(() => {
			$('.outer-menu').removeClass('outer-menu--opened');
			return false;
		});
	};

	sandwich();
	const license = function () {
		const items = document.querySelectorAll('.license__item:nth-child(n+3)');

		if (window.innerWidth <= 575) {
			items.forEach((e) => {
				e.classList.add('license__item--hide');
			});
		}
	};

	license();

	ymaps.ready(init);

	function init() {
		const myMap = new ymaps.Map('map', {
			center: [55.79845291451638, 37.60133543115234],
			zoom: 16,
			controls: ['zoomControl'],
		});

		if (window.innerWidth <= 860) {
			myMap.setCenter([55.7961, 37.6100]);
		}

		const myGeoObjects = [];

		myGeoObjects[0] = new ymaps.Placemark([55.79891505156673, 37.60801823302802], {
			clusterCaption: 'Заголовок',
			// balloonContentBody: 'Текст в балуне',
		}, {
			// Необходимо указать данный тип макета.
			iconLayout: 'default#image',
			iconImageHref: 'images/content/marker.png',
			// Размеры метки.
			iconImageSize: [116, 130],
			// Смещение левого верхнего угла иконки относительно
			// её «ножки» (точки привязки).
			iconImageOffset: [-3, -42],
		});

		const clusterIcons = [{
			href: 'images/content/marker.png',
			size: [116, 130],
			offset: [0, 0],
		}];

		const clusterer = new ymaps.Clusterer({
			clusterDisableClickZoom: false,
			clusterOpenBalloonOnClick: false,
			// Устанавливаем стандартный макет балуна кластера "Карусель".
			clusterBalloonContentLayout: 'cluster#balloonCarousel',
			// Устанавливаем собственный макет.
			// clusterBalloonItemContentLayout: customItemContentLayout,
			// Устанавливаем режим открытия балуна.
			// В данном примере балун никогда не будет открываться в режиме панели.
			clusterBalloonPanelMaxMapArea: 0,
			// Устанавливаем размеры макета контента балуна (в пикселях).
			clusterBalloonContentLayoutWidth: 300,
			clusterBalloonContentLayoutHeight: 200,
			// Устанавливаем максимальное количество элементов в нижней панели на одной странице
			clusterBalloonPagerSize: 5,
			// Настройка внешего вида нижней панели.
			// Режим marker рекомендуется использовать с небольшим количеством элементов.
			// clusterBalloonPagerType: 'marker',
			// Можно отключить зацикливание списка при навигации при помощи боковых стрелок.
			// clusterBalloonCycling: false,
			// Можно отключить отображение меню навигации.
			// clusterBalloonPagerVisible: false
		});

		clusterer.add(myGeoObjects);
		myMap.geoObjects.add(clusterer);
	}
}
